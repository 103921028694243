import * as THREE from 'three';
import {rotateAroundPoint} from '../utils/math';

const getWenymMesh = (radius = 4, circleSegments = 1000, depth = null) => {
    // const radius = 4; // height of the triangle is 1.5 * radius
    const radians = 60 * Math.PI / 180;
    const triangleHeight = radius*1.5;
    const outlineWidth = radius * 2 * Math.PI / 100;
    depth = depth === null
        ? outlineWidth
        : depth;

    const geometry = new THREE.BufferGeometry();
    const points = []; // in BufferGeometry there are just the faces and their vertices inside the "position" array
    const vertices = []; // this will hold each unique vertex to make it easier to add the faces to the points array
    //         1
    //         +            |   ^
    //        / \           |   |
    //       /60°\          |   |radius*1
    //      /     \         |   |
    //     /       \        |   V _____ 0|0|0
    //    /         \       |   ^
    //   /60°     60°\      |   |radius*.5
    //  +-------------+     |   V
    //  2             3
    //----------------------------------------------------------------
    //         + B
    //        /| <-- 30° (60°/2)
    //       / |
    //  c   /  |
    //     /   | a = radius*1.5
    //    /    |
    //   /60°  |
    //  +------+
    //  A  b   C     b = a / Math.tan(alpha);
    //----------------------------------------------------------------
    //           1                       7          
    //          / \                     / \
    //         / 0 \                   / 6 \
    //        / / \ \                 / / \ \
    //       / /   \ \               / /   \ \
    //      / /     \ \             / /     \ \
    //     / /       \ \           / /       \ \
    //    / /         \ \         / /   BACK  \ \
    //   / /           \ \       / /           \ \
    //  / 2-------------4 \     / 10------------8 \
    // 3-------------------5   11------------------9
    //----------------------------------------------------------------
    const halfWidth = triangleHeight / Math.tan(radians); // b === halfWidth
    const halfDepth = depth/2;
    vertices.push(
        [0, radius-outlineWidth, halfDepth],
        [0, radius+outlineWidth, halfDepth]
    );

    let y = -radius*.5;
    vertices.push([
        -halfWidth + outlineWidth * Math.cos(radians/2), 
        y + outlineWidth * Math.sin(radians/2), 
        halfDepth
    ]);
    vertices.push([
        -halfWidth + outlineWidth * Math.cos(radians/2 + Math.PI), 
        y + outlineWidth * Math.sin(radians/2 + Math.PI), 
        halfDepth
    ]);

    vertices.push([
        halfWidth + outlineWidth * Math.cos(Math.PI - radians/2), 
        y + outlineWidth * Math.sin(Math.PI - radians/2), 
        halfDepth
    ]);
    vertices.push([
        halfWidth + outlineWidth * Math.cos(Math.PI - radians/2 + Math.PI), 
        y + outlineWidth * Math.sin(Math.PI - radians/2 + Math.PI), 
        halfDepth
    ]);

    points.push(
        ...vertices[0], ...vertices[1], ...vertices[3], 
        ...vertices[3], ...vertices[2], ...vertices[0], 

        ...vertices[2], ...vertices[3], ...vertices[5], 
        ...vertices[5], ...vertices[4], ...vertices[2], 

        ...vertices[4], ...vertices[5], ...vertices[0], 
        ...vertices[0], ...vertices[5], ...vertices[1]
    );

    //====Backside====
    vertices.push(
        [0, radius-outlineWidth, -halfDepth],
        [0, radius+outlineWidth, -halfDepth]
    );

    vertices.push([
        -halfWidth + outlineWidth * Math.cos(radians/2), 
        y + outlineWidth * Math.sin(radians/2), 
        -halfDepth
    ]);
    vertices.push([
        -halfWidth + outlineWidth * Math.cos(radians/2 + Math.PI), 
        y + outlineWidth * Math.sin(radians/2 + Math.PI), 
        -halfDepth
    ]);

    vertices.push([
        halfWidth + outlineWidth * Math.cos(Math.PI - radians/2), 
        y + outlineWidth * Math.sin(Math.PI - radians/2), 
        -halfDepth
    ]);
    vertices.push([
        halfWidth + outlineWidth * Math.cos(Math.PI - radians/2 + Math.PI), 
        y + outlineWidth * Math.sin(Math.PI - radians/2 + Math.PI), 
        -halfDepth
    ]);

    points.push(
        ...vertices[6], ...vertices[7], ...vertices[11],
        ...vertices[11], ...vertices[10], ...vertices[6],

        ...vertices[10], ...vertices[11], ...vertices[9],
        ...vertices[9], ...vertices[8], ...vertices[10],

        ...vertices[8], ...vertices[9], ...vertices[6],
        ...vertices[6], ...vertices[9], ...vertices[7]
    );
    //====/Backside====

    //====Triangle Sides====
    // 1--------3--------5--------1
    // |        |        |        |  Outside
    // 7--------9--------11-------7            
    //
    // 0--------4--------2--------0
    // |        |        |        |  Inside
    // 6--------10-------8--------6
    points.push(
        // Outside
        ...vertices[1], ...vertices[7], ...vertices[9],
        ...vertices[9], ...vertices[3], ...vertices[1],
        
        ...vertices[3], ...vertices[9], ...vertices[11],
        ...vertices[11], ...vertices[5], ...vertices[3],
        
        ...vertices[5], ...vertices[11], ...vertices[7],
        ...vertices[7], ...vertices[1], ...vertices[5],
        // Inside
        ...vertices[0], ...vertices[6], ...vertices[10],
        ...vertices[10], ...vertices[4], ...vertices[0],
        
        ...vertices[4], ...vertices[10], ...vertices[8],
        ...vertices[8], ...vertices[2], ...vertices[4],
        
        ...vertices[2], ...vertices[8], ...vertices[6],
        ...vertices[6], ...vertices[0], ...vertices[2]
    );
    //====/Triangle Sides====

    const circleRadius = radius/2;
    const angleStep = 2*Math.PI / circleSegments;
    const vertexIndexCircle = vertices.length; // first vertex of the circle
    let cameFromGap = false;
    for(let i = 0; i < circleSegments; i++)
    {
        const angle = angleStep * i;
        if(angle > radians/2 && angle < Math.PI - radians/2)
        {
            cameFromGap = true;
            continue;
        }
        
        let xMultiplier = Math.cos(angle);
        let yMultiplier = Math.sin(angle);
        vertices.push(
            [
                (circleRadius - outlineWidth/2) * xMultiplier, 
                (circleRadius - outlineWidth/2) * yMultiplier, 
                halfDepth,
            ],
            [
                (circleRadius + outlineWidth/2) * xMultiplier, 
                (circleRadius + outlineWidth/2) * yMultiplier, 
                halfDepth,
            ],
            [
                (circleRadius - outlineWidth/2) * xMultiplier, 
                (circleRadius - outlineWidth/2) * yMultiplier, 
                -halfDepth,
            ],
            [
                (circleRadius + outlineWidth/2) * xMultiplier, 
                (circleRadius + outlineWidth/2) * yMultiplier, 
                -halfDepth
            ]
        );

        if(i > 0 && !cameFromGap)
        {   
            //    -7~~~-3
            //    /      \
            //   /   UP   \
            // -6~~~~~~~~~-2
            //    -5~~~-1
            //    /      \
            //   /  DOWN  \
            // -4~~~~~~~~~~0
            const vertexIndex = vertices.length-1;
            points.push(
                // up
                ...vertices[vertexIndex-2], ...vertices[vertexIndex-3], ...vertices[vertexIndex-6],
                ...vertices[vertexIndex-6], ...vertices[vertexIndex-3], ...vertices[vertexIndex-7],
                // down
                // -1 -5
                //  0 -4
                ...vertices[vertexIndex], ...vertices[vertexIndex-5], ...vertices[vertexIndex-1],
                ...vertices[vertexIndex], ...vertices[vertexIndex-4], ...vertices[vertexIndex-5],
                // inner
                // -3 -7
                // -1 -5
                ...vertices[vertexIndex-1], ...vertices[vertexIndex-7], ...vertices[vertexIndex-3],
                ...vertices[vertexIndex-1], ...vertices[vertexIndex-5], ...vertices[vertexIndex-7],
                // outer
                // -6 -2
                // -4  0
                ...vertices[vertexIndex-4], ...vertices[vertexIndex-2], ...vertices[vertexIndex-6],
                ...vertices[vertexIndex-4], ...vertices[vertexIndex], ...vertices[vertexIndex-2]
            );
        }
        cameFromGap = false;
    }

    let vertexIndex = vertices.length-1;
    // close the ring
    //    v-3~~~c
    //    /      \
    //   /   UP   \
    // v-2~~~~~~~~~c+1
    //    v-1~~~c+2
    //    /      \
    //   /  DOWN  \
    //  v~~~~~~~~~~c+3
    points.push(
        // up
        ...vertices[vertexIndexCircle+1], ...vertices[vertexIndexCircle], ...vertices[vertexIndex-2],
        ...vertices[vertexIndex-2], ...vertices[vertexIndexCircle], ...vertices[vertexIndex-3],
        // down
        // c+2 v-1
        // c+3 v
        ...vertices[vertexIndexCircle+3], ...vertices[vertexIndex-1], ...vertices[vertexIndexCircle+2],
        ...vertices[vertexIndexCircle+3], ...vertices[vertexIndex], ...vertices[vertexIndex-1],
        // inner
        // c   v-3
        // c+2 v-1
        ...vertices[vertexIndexCircle+2], ...vertices[vertexIndex-3], ...vertices[vertexIndexCircle],
        ...vertices[vertexIndexCircle+2], ...vertices[vertexIndex-1], ...vertices[vertexIndex-3],
        // outer
        // v-2 c+1
        //  v  c+3
        ...vertices[vertexIndex], ...vertices[vertexIndexCircle+1], ...vertices[vertexIndex-2],
        ...vertices[vertexIndex], ...vertices[vertexIndexCircle+3], ...vertices[vertexIndexCircle+1]
    );
                
    // rays 
    const MathGoldenRatio = (1 + Math.sqrt(5)) / 2;// 1.618033988749...
    const initialRayLength = radius/2 * MathGoldenRatio;
    const sideLength = 2 * halfWidth;
    const bottomRays = 7;
    const sideRays = 8;
    const bottomRayDistance = sideLength / (bottomRays + 1);
    const sideRayDistance = sideLength / (sideRays + 1);

    const createRay = (x, y, length, rotationData = null) => {
        // 0--1
        // |  |
        // |  |
        // |  |
        // |  |
        // 2--3
        //
        // 4--5
        // |  |
        // |  |
        // |  |
        // |  |
        // 6--7
        let vertexIndex = vertices.length;
        vertices.push(
            [x - outlineWidth/2, y, halfDepth],
            [x + outlineWidth/2, y, halfDepth],
            [x - outlineWidth/2, y - length, halfDepth],
            [x + outlineWidth/2, y - length, halfDepth],

            [x - outlineWidth/2, y, -halfDepth],
            [x + outlineWidth/2, y, -halfDepth],
            [x - outlineWidth/2, y - length, -halfDepth],
            [x + outlineWidth/2, y - length, -halfDepth]
        );
        if(rotationData)
        {
            const pointX = rotationData.x;
            const pointY = rotationData.y;
            const angle = rotationData.angle;
            for(let i = vertexIndex; i < vertices.length; i++)
            {
                const {x, y} = rotateAroundPoint(pointX, pointY, vertices[i][0], vertices[i][1], angle);
                vertices[i][0] = x;
                vertices[i][1] = y;
            }
        }

        //-7---6
        // |   |
        // | U |
        // | P |
        // |   |
        //-5---4
        //
        //-3---2
        // | D |
        // | O |
        // | W |
        // | N |
        //-1---0
        vertexIndex = vertices.length-1;
        points.push(
            // up
            ...vertices[vertexIndex-7], ...vertices[vertexIndex-5], ...vertices[vertexIndex-6],
            ...vertices[vertexIndex-5], ...vertices[vertexIndex-4], ...vertices[vertexIndex-6],
            // down
            // -2 -3
            //  0 -1
            ...vertices[vertexIndex], ...vertices[vertexIndex-3], ...vertices[vertexIndex-2],
            ...vertices[vertexIndex], ...vertices[vertexIndex-1], ...vertices[vertexIndex-3],
            // left
            // -7 -5
            // -3 -1
            ...vertices[vertexIndex-3], ...vertices[vertexIndex-5], ...vertices[vertexIndex-7],
            ...vertices[vertexIndex-3], ...vertices[vertexIndex-1], ...vertices[vertexIndex-5],
            // right
            // -4 -6
            //  0 -2
            ...vertices[vertexIndex], ...vertices[vertexIndex-6], ...vertices[vertexIndex-4],
            ...vertices[vertexIndex], ...vertices[vertexIndex-2], ...vertices[vertexIndex-6],
            // top
            // -6 -7
            // -2 -3
            ...vertices[vertexIndex-2], ...vertices[vertexIndex-7], ...vertices[vertexIndex-6],
            ...vertices[vertexIndex-2], ...vertices[vertexIndex-3], ...vertices[vertexIndex-7],
            // bottom
            // -5 -4
            // -1 -0
            ...vertices[vertexIndex-1], ...vertices[vertexIndex-4], ...vertices[vertexIndex-5],
            ...vertices[vertexIndex-1], ...vertices[vertexIndex], ...vertices[vertexIndex-4]
        );
    }
    const bottomY = -radius*.5 - outlineWidth*1.5;
    let bottomX = 0;
    createRay(bottomX, bottomY, initialRayLength);
    
    let lastRayLength = initialRayLength;
    for(let i = 0; i < (bottomRays-1)/2; i++)
    {
        let x = bottomX - (i+1) * bottomRayDistance;
        const rayLength = lastRayLength / MathGoldenRatio;
        lastRayLength = rayLength;

        createRay(x, bottomY, rayLength);
        createRay(Math.abs(x), bottomY, rayLength);
    }

    for(let side = 0; side < 2; side++)
    {
        const rotationData = {
            x: 0,
            y: 0,
            angle: side === 0
                ? Math.PI/2 + radians/2
                : -Math.PI/2 - radians/2
        };

        createRay(-sideRayDistance/2, bottomY, initialRayLength, rotationData);
        createRay(sideRayDistance/2, bottomY, initialRayLength, rotationData);
        
        let lastRayLength = initialRayLength;
        for(let i = 0; i < (sideRays-2)/2; i++)
        {
            let x = -sideRayDistance/2 - (i+1) * bottomRayDistance;
            const rayLength = lastRayLength / MathGoldenRatio;
            lastRayLength = rayLength;

            createRay(x, bottomY, rayLength, rotationData);
            createRay(Math.abs(x), bottomY, rayLength, rotationData);
        }
    }

    geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(points), 3));
    // geometry.setAttribute('normal', new THREE.BufferAttribute(new Float32Array(normals), 3));
    // geometry.setAttribute('color', new THREE.BufferAttribute(new Float32Array(colors), 3));
    // geometry.setAttribute('uv', new THREE.BufferAttribute(new Float32Array(uvs), 2));
                
    geometry.computeVertexNormals();

    geometry.addGroup(0/* first vertex */, points.length/* count - how many vertices */, 0/* material index */);
    
    const material = [
        new THREE.MeshPhongMaterial({color: '#ff6000'}),
        // new THREE.MeshLambertMaterial({wireframe:true})
    ];
    const mesh = new THREE.Mesh(geometry, material);
    mesh.castShadow	= true;
    return mesh;
};

export default getWenymMesh;
