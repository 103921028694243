import './App.css';
import React from 'react';

import * as THREE from 'three';
import {EffectComposer} from 'three/examples/jsm/postprocessing/EffectComposer';
import {RenderPass} from 'three/examples/jsm/postprocessing/RenderPass';

import {SSAARenderPass} from 'three/examples/jsm/postprocessing/SSAARenderPass';

import {GlitchPass as CustomGlitchPass} from './postprocessing/GlitchPass';
import {UnrealBloomPass} from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import {AfterimagePass} from 'three/examples/jsm/postprocessing/AfterimagePass';
import {FilmPass} from 'three/examples/jsm/postprocessing/FilmPass';

import TWEEN from '@tweenjs/tween.js';

import getWenymMesh from './meshes/Wenym';

import audioGlitch1 from './audio/Computer-glitch-accent.mp3';
import audioGlitch2 from './audio/Computer-glitch-accent-2.mp3';
import audioGlitch3 from './audio/Computer-glitch-accent-3.mp3';
import audioGlitch4 from './audio/Computer-glitch-accent-4.mp3';

const config = {
    postProcessing: true,
    colors: {
        ambientLight: 0x444444,
        directionalLight: 0xcccccc,
    },
    clicksNeeded: 42
};

export default class App extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            clicks: 0
        };

        this.lastTime = null; // last time in milliseconds
        this.frameDuration = 1000/60; // target 60fps

        this.start = this.start.bind(this);
        this.stop = this.stop.bind(this);
        this.animate = this.animate.bind(this);
        this.handleClick = this.handleClick.bind(this);

        //====Init Audio====
        this.audioGlitches = [
            new Audio(audioGlitch1),
            new Audio(audioGlitch2),
            new Audio(audioGlitch3),
            new Audio(audioGlitch4)
        ];
        for(const audio of this.audioGlitches)
        {    
            audio.volume = .01;
            // audio.addEventListener('loadeddata', () => {
            //     // audio.duration; // is now available
            // });
        }
        //====/====

        window.onresize = function(){
            const width = window.innerWidth;
            const height = window.innerHeight;
            this.camera.aspect = width / height;
            this.camera.updateProjectionMatrix();
            this.renderer.setSize(width, height);
        };
    }
  
    componentDidMount()
    {
        const width = window.innerWidth;
        const height = window.innerHeight;
  
        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera(75, width / height, .1, 1000);
        this.renderer = new THREE.WebGLRenderer({antialias: true});
        this.renderer.setSize(width, height);

        //====Post-Processing====
        const composer = new EffectComposer(this.renderer);
        const renderPass = new RenderPass(this.scene, this.camera);
        composer.addPass(renderPass);

        // with EffectComposer, "antialias: true" of WebGLRenderer (which uses MSAA) won't work...
        // Antialias with SSAARenderPass will look better than MSAA but it costs more peformance
        const ssaaRenderPass = new SSAARenderPass(/* scene, camera, clearColor, clearAlpha */this.scene, this.camera);
        composer.addPass(ssaaRenderPass);

        // const glitchPass = new GlitchPass(/* size */42);
        const glitchPass = new CustomGlitchPass(/* size, noiseMultiplier, glitchTriggerMin, glitchTriggerMax */32, .01);
        // glitchPass.goWild = true;
        glitchPass.onGlitchStart(duration => {
            const audio = this.audioGlitches[Math.floor(Math.random() * this.audioGlitches.length)];
            audio.play();
        });
        composer.addPass(glitchPass);
        this.glitchPass = glitchPass;

        const unrealBloomPass = new UnrealBloomPass(/* resolution, strength, radius, threshold */256, .5, 1.2, 0);
        composer.addPass(unrealBloomPass);

        const afterimagePass = new AfterimagePass(.8);
        composer.addPass(afterimagePass);

        const filmPass = new FilmPass(/* noiseIntensity, scanlinesIntensity, scanlinesCount, grayscale */.4, .6, 4096, 0);
        composer.addPass(filmPass);
        //====Post-Processing====

        this.executeRender = config.postProcessing
            ? () => composer.render() // with post processing
            : () => this.renderer.render(this.scene, this.camera); // without post processing

        this.renderer.shadowMap.enabled = true;
        
        this.camera.up = new THREE.Vector3(0, 0, 1);
        this.camera.position.z = 16;

        const center = new THREE.Vector3();
        this.camera.lookAt(center);
        this.camera.rotation.z = 0;
  
        this.contentElement.appendChild(this.renderer.domElement);

        this.setupScene();
        this.start();
    }
  
    componentWillUnmount()
    {
        this.stop();
        this.contentElement.removeChild(this.renderer.domElement);
    }

    setupScene()
    {
        {
            let light = new THREE.AmbientLight(config.colors.ambientLight);
            this.scene.add(light);

            light = new THREE.DirectionalLight(config.colors.directionalLight, 1);
            light.position.set(5, 5, 5);
            light.target.position.set(0, 0, 0);
            light.castShadow = true;
            light.shadow.camera.near = .01;
            light.shadow.camera.far = 15;
            light.shadow.camera.fov = 45;
            light.shadow.camera.left = -8;
            light.shadow.camera.right =  8;
            light.shadow.camera.top =  5;
            light.shadow.camera.bottom = -5;
            //light.shadow.cameraVisible = true;
            light.shadow.bias = .001;
            light.shadow.mapSize.width = 1024;
            light.shadow.mapSize.height = 1024;
            this.scene.add(light);
        }

        this.wenymMesh = getWenymMesh();
        this.scene.add(this.wenymMesh);

        this.rotationDuration = 8000;
        this.tweenRotation = new TWEEN.Tween(this.wenymMesh.rotation).to({y: Math.PI}, this.rotationDuration)
            // .easing(TWEEN.Easing.Quadratic.InOut)
            .repeat(Infinity)
            .start();

        this.tweenCameraTrackingShot = new TWEEN.Tween(this.camera.position).to({z: 0}, 12000)
            .easing(TWEEN.Easing.Quadratic.Out)
            .onComplete(() => {
                this.glitchPass.active = false;

                window.location.pathname = '/exec';
            });
    }
  
    start()
    {
        if(!this.frameId)
            this.animate(Date.now());
    }
  
    stop()
    {
        cancelAnimationFrame(this.frameId);
    }
  
    animate(now/*DOMHighResTimeStamp in milliseconds */)
    { 
        this.frameId = window.requestAnimationFrame(this.animate);

        this.lastTime = this.lastTime || now - this.frameDuration; // initialize lastTime with "now - frameDuration"
        const delta = Math.min(200, now - this.lastTime);
        this.lastTime = now;

        this.update(delta/1000, now);
        this.renderScene();
    }

    update(delta, now)
    {
        TWEEN.update(); // updates all Tweens
    }
  
    renderScene()
    {
        this.executeRender();
    }

    handleClick()
    {
        this.setState(prev => ({clicks: prev.clicks + 1}), () => {
            if(this.state.clicks === config.clicksNeeded)
            {
                // this.tweenRotation.repeat(0); // this will work but adding easing during animation looks bad

                this.tweenRotation.stop();
                const duration = Math.abs(Math.PI - this.wenymMesh.rotation.y) * this.rotationDuration / Math.PI;
                this.tweenRotation = new TWEEN.Tween(this.wenymMesh.rotation).to({y: Math.PI}, duration)
                    .easing(TWEEN.Easing.Quadratic.Out)
                    .start();

                this.tweenCameraTrackingShot.start();
            }
        });
    }
  
    render()
    {
        return (
            <div ref={mount => {this.contentElement = mount;}} onClick={this.handleClick} />
        );
    }
}
